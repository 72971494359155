
import { Options, Vue } from 'vue-class-component'
import InvoiceTablePage from '../components/invoiceTable.vue'
import axios from '@/plugins/axios'
import { ElMessage, ElMessageBox } from 'element-plus'

interface Result {
  total: number
  list: []
  data: []
}
@Options({
  components: {
    InvoiceTablePage
  }
})
export default class InvoicePage extends Vue {
  listInvoices: any = []
  currentPage = 1
  totalRows = 0
  perPage = 20
  isShowWarning = true
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }
  selectedCompany = ''
  selectedEmail = ''
  daterange: any = []

  companyData: any = []

  showFilterMenu = false
  isShowInvoiceForm = false

  isSuperAdmin = false
  isPendingExport = false
  invoicesSelected: any = []

  ListCards = []
  invoiceForm: any = {
    _id: '',
    account: '',
    items: []
  }

  rules: any = {
    account: [{ required: true, trigger: 'blur', message: 'Please input the account email' }],
  }

  async created() {
    await this.$store.dispatch('setLoading', true, { root: true })
    const mainUserID = this.$store.state.auth.dataLogin.id
    const selectedUserId = this.$store.state.auth?.accountSelected?.owner

    if (!selectedUserId || this.isCustomInvoice) {
      this.isShowWarning = false
    } else {
      this.isShowWarning = mainUserID == selectedUserId ? false : true
    }

    if (!this.isSuperAdmin) {
      await this.getListCards()
    }

    if (!this.isShowWarning) {
      this.getListInvoices(0)
    }
    this.isSuperAdmin = await this.$store.state.auth.dataLogin.type

    await this.getAll()
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  async getListInvoices(page = 0) {
    await this.$store.dispatch('setLoading', true, { root: true })

    let res: Result = {
      total: 0,
      list: [],
      data: []
    }

    if (this.isSuperAdmin) {
      // console.log(
      //   `params: {page: ${page}, limit: ${this.perPage}, name: ${this.selectedCompany}, user_id: ${this.selectedEmail}} `
      // )

      res = await axios.get('admin/invoice', {
        headers: this.headers,
        params: {
          page: page === 0 ? page + 1 : page,
          limit: this.perPage,
          name: this.selectedCompany,
          user_id: this.selectedEmail,
          type: this.isCustomInvoice ? 'custom' : '',
          from:
            (this.daterange &&
              this.daterange[0] &&
              new Date(this.daterange[0])) ||
            null,
          to:
            (this.daterange &&
              this.daterange[0] &&
              this.daterange[1] &&
              new Date(this.daterange[1])) ||
            null
        }
      })

      if (res) {
        // console.log(`res: ${JSON.stringify(res)}`)
        this.totalRows = res.total
        this.listInvoices = res.data
      }
    } else {
      let currentAccount = this.$store.state.auth?.accountSelected
      res = await axios.get('admin/invoice', {
        headers: this.headers,
        params: {
          page: page === 0 ? page + 1 : page,
          limit: this.perPage,
          name: currentAccount.name,
          user_id: currentAccount.owner,
          type: this.isCustomInvoice ? 'custom' : '',
          from:
            (this.daterange &&
              this.daterange[0] &&
              new Date(this.daterange[0])) ||
            null,
          to:
            (this.daterange &&
              this.daterange[0] &&
              new Date(this.daterange[1])) ||
            null
        }
      })

      if (res) {
        // console.log(`res: ${JSON.stringify(res)}`)
        this.totalRows = res.total
        this.listInvoices = res.data
      }
    }

    await this.$store.dispatch('setLoading', false, { root: true })
  }
  handleCurrentChange(index = 1) {
    this.currentPage = index
    this.getListInvoices(index)
  }
  handleSizeChange(val: number) {
    this.currentPage = 1
    this.perPage = val
    this.getListInvoices(this.currentPage)
  }
  handleConfirm() {
    this.$router.push({ name: 'DashboardPage' })
  }

  /* Get customer company name/email */
  async getAll() {
    let res = await axios.get('admin/account/get-all', {
      headers: this.headers
    })
    if (res) {
      // console.log(`getAll: ${JSON.stringify(res)}`)
      // console.log(res)
      // console.log(`companyData: ${JSON.stringify(res.data)}`)
      // console.log(
      //   `companyData owner email: ${JSON.stringify(res.data[0].owner.email)}`
      // )
      this.companyData = res.data
    }
  }

  /* Filter */
  toggleFilterMenu() {
    this.showFilterMenu = !this.showFilterMenu
  }

  clearFilterMenu() {
    this.currentPage = 1
    this.selectedCompany = ''
    this.selectedEmail = ''
    this.daterange = []
    this.listInvoices = []
    this.invoicesSelected = []

    this.getListInvoices()
  }

  filterInvoices() {
    this.listInvoices = []
    this.invoicesSelected = []

    this.getListInvoices()
  }

  async exportInvoices() {
    if (this.invoicesSelected.length > 0) {
      let params = []
      for (let invoice of this.invoicesSelected) {
        let invoiceData = {
          Total: '$' + invoice.total,
          'Issue Date': invoice.releaseDate,
          'ID View': invoice.idView,
          Status: invoice.status,
          'Package Name': ''
        }
        let connectionsFormat: any[] = []
        invoice.connections.forEach((i: any) => {
          let connection = {
            name: (i.connection && i.connection.name) || '',
            price: '$' + (i.price || 0),
            quantity: 1
          }
          connectionsFormat.push(connection)
        })
        connectionsFormat.splice(0, 1)
        invoiceData['Package Name'] = JSON.stringify(connectionsFormat)
        params.push(invoiceData)
      }
      await axios
        .post('/user/invoice/export', params, {
          headers: this.headers
        })
        .then(function (response) {
          window.location = response?.data?.linkFileExport
          ElMessage.success('Download successfully!')
        })
        .catch(function (error) {
          console.log(error)
          ElMessage.error(error)
        })
    } else {
      let body = {
        query: {
          name: this.selectedCompany,
          user_id: this.selectedEmail,
          from: (this.daterange && this.daterange[0]) || null,
          to: (this.daterange && this.daterange[0] && this.daterange[1]) || null
        }
      }
      this.isPendingExport = true
      await axios
        .post('/user/invoice/export', body, {
          headers: this.headers
        })
        .then(function (response) {
          window.location = response?.data?.linkFileExport
          ElMessage.success('Download successfully!')
        })
        .catch(function (error) {
          console.log(error)
          ElMessage.error(error)
        })
        .finally(() => (this.isPendingExport = false))
    }
  }

  getMultipleSelection(val: any) {
    this.invoicesSelected = val
  }

  get isCustomInvoice () {
    return this.$route.path === '/custom-invoice'
  }

  toggleInvoiceForm() {
    if (!this.companyData.length) {
      this.$message.warning('Please wait for loading customer accounts')
    } else {
      this.isShowInvoiceForm = !this.isShowInvoiceForm
    }
  }

  editInvoice(invoice: any) {
    this.invoiceForm = invoice
    this.toggleInvoiceForm()
  }

  addItem() {
    this.invoiceForm.items.push({
      name: '',
      quantity: 1,
      price: 0,
      description: '',
    });
  }

  handleDeleteItem(index: number) {
    this.invoiceForm.items.splice(index, 1);
  }

  async handleSaveInvoice() {
    const refForm = this.$refs['invoiceFormRef'] as InstanceType<typeof HTMLFormElement>;

    // Sau đó mới sử dụng validate
    const isValidate = await refForm.validate();
    if (!isValidate) return;
    if (!this.invoiceForm.items.length) {
      this.$message.warning('Please add at least one item')
    }

    const isInvalid = this.invoiceForm.items.some((item:any) => !item.name)
    if (isInvalid) {
      this.$message.warning('Please input name')
    } else (
      await ElMessageBox.confirm('Are you sure to save Invoice?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      })
        .then(async() => {
          await axios
            .post('/user/invoice/custom-invoice', this.invoiceForm, {
              headers: this.headers
            })
            .then((response: any) => {
              if (response.success) {
                ElMessage.success(response.message)
                this.$store.dispatch('reRender')
              } else {
                ElMessage.error(response.message)
              }
            }).catch(function (error) {
              console.log(error)
              ElMessage.error(error)
            })
            .finally(() => {
              this.isShowInvoiceForm = false
            })
        })
        .catch((error) => {
  
          console.log(error)
        })
    )

  }

  async getListCards() {
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Result
    res = await axios.get('user/list-of-payment-card?type=list', {
      headers: this.headers,
      params: {
        account: localStorage.getItem('account')
      }
    })
    if (res) {
      this.ListCards = res.list || []
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }

}
